import React, { useCallback } from 'react';

import { ContainerTipoAcesso, BtnGroupContainer } from './style';
import { Button } from 'react-bootstrap';
import { useSearch } from '../../SearchContext';

import { MdSearch } from 'react-icons/md';

export const TipoAcessoCards: React.FC = () => {
  const { tipoAcesso, setTipoAcesso, setValue, searchTextWatch } = useSearch();

  const renderButton = useCallback(
    (value: number, label: string, className: string) => {
      return (
        <Button
          value={value}
          className={`btn-group ${
            tipoAcesso === value ? 'btn-group-active' : ''
          } ${className}`}
          onClick={() => setTipoAcesso(value)}
          type="button"
        >
          {label}
        </Button>
      );
    },
    [setTipoAcesso, tipoAcesso],
  );

  return (
    <ContainerTipoAcesso>
      <BtnGroupContainer>
        {renderButton(0, 'Meus cards', 'cards')}
        {renderButton(1, 'Loja', 'loja')}
        {renderButton(2, 'Grupo de Empresa', 'empresa')}
        {renderButton(3, 'Público', 'publico')}
      </BtnGroupContainer>
      <div className="containerSearchInput">
        <input
          type="text"
          className="search"
          onChange={(e) => {
            setValue('search', e.target.value);
          }}
          placeholder="Pesquisar"
          value={searchTextWatch}
        />
        <MdSearch />
      </div>
    </ContainerTipoAcesso>
  );
};
