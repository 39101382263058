import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import {
  CacheLastSearchProps,
  ColumnProps,
  DataResponse,
  Field,
  Filter,
  IFilter,
  JsonEdit,
  SearchContextData,
  SearchProviderProps,
  SortCamposProps,
  TotalsProps,
} from './types';
import { useQueryClient } from 'react-query';
import { FilterContext } from '~/context/filter';
// eslint-disable-next-line import/no-cycle
import api from '~/services/api';
import { getUserData } from '~/services/user';
import { ThemeContext } from 'styled-components';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useCacheTelas } from '~/hooks/useCacheTelas';
import { nanoid } from 'nanoid';
import { format } from 'date-fns';
import { InformacaoTelasAcessadasContext } from '~/context/InformacaoTelasAcessadas';
import useDebounce from '~/hooks/useDebounce';
import { useForm } from 'react-hook-form';
import { loadingContext } from '~/context/loading';
import { useTableViewManager } from '~/context/tableViewManager';

export const SeachContext = createContext({} as SearchContextData);

const MySwal = withReactContent(Swal);
export function SearchContextProvider({
  children,
  codTela,
  newData,
  orderModulo,
  onRowClick,
  onlyReport,
  orderTelas = false,
  customButtons,
}: SearchProviderProps): JSX.Element {
  const { colors } = useContext(ThemeContext);
  const {
    AddFilterOrUpdate,
    RemoveFilter,
    filtersContexto,
    RemoveFilterisBlock,
  } = useContext(FilterContext);
  const { debouncedFn } = useDebounce();

  const { setValue, getValues, watch } = useForm();

  const { screensInformacoes } = useContext(InformacaoTelasAcessadasContext);
  const {
    loadingTableSearch,
    loadingPageSearch,
    handleLoadingPageSearch,
    handleLoadingTableSearch,
  } = useContext(loadingContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingTable, setLoadingTable] = useState<boolean>(true);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [isUpdateCard, setUpdateCard] = useState<boolean>(false);
  const [idFiltro, setIdFiltro] = useState<string>('');
  const [searchOnClick, setSearchOnClick] = useState<boolean>(false);
  const [isRemoveClick, setIsRemoveClick] = useState<boolean>(false);

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [backSearch, setBackSearch] = useState<boolean>(false);
  const [isOpenFields, setOpenFields] = useState<boolean>(false);

  const [inputSearch, setInputSearch] = useState<string>('');
  const [dataEdit, setDataEdit] = useState<JsonEdit>();
  const [isCardFixed, setIsCardFixed] = useState<boolean>(false);
  /**
   * Armazena os campos da tela possiveis paa pesquisa
   */
  const [searchFields, setSearchFields] = useState<Field[]>([]);
  /**
   * Armazena os cards da tela
   */

  const [columns, setColumns] = useState([]);
  const [showImpressao, setShowImpressao] = useState<boolean>(false);

  // States da paginação
  const [oldPage, setOldPage] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [isUpdateLimit, setIsUpdateLimit] = useState<boolean>(false);
  const [rowsCount, setRowsCount] = useState(0);
  const [enabledCaching, setEnabledCaching] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const [linkMaterial, setLinkMaterial] = useState('');
  const [View, setView] = useState<string>('PADRÃO');
  const [sortCampo, setSortCampo] = useState<any>([]);
  const [sortCampos, setSortCampos] = useState<SortCamposProps[]>([]);
  const [openPopupView, setOpenPopupView] = useState<boolean>(false);
  const [openOrderModal, setOpenOrderModal] = useState<boolean>(false);
  const [abrePesquisa, setAbrePesquisa] = useState<boolean>(false);
  const [totalsAgregadores, setTotalsAgregadores] = useState<TotalsProps>({
    count: '',
  });
  const [openOrderTelasModal, setOpenOrderTelasModal] =
    useState<boolean>(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const filtersDaTela =
    filtersContexto.find((item: IFilter) => item.codTela === codTela)?.itens ||
    [];
  const user = getUserData();

  const [listaDeFiltrosOrdenados, setListaDeFiltrosOrdenados] = useState<
    Field[]
  >([]);

  const [tipoAcesso, setTipoAcesso] = useState<number>(0);
  const searchTextWatch = watch('search');

  const { onResetAllStates } = useTableViewManager();

  const updateFiltroContextoWhenOnRowClick = useCallback(
    (pag: number, limite: number, columnsSeach: any) => {
      const orderBy = getOrderBy(columnsSeach);
      queryClient.setQueryData(`cacheLastSearch_${codTela}`, {
        pag,
        limite,
        orderBy,
        shouldReturnSearch: true,
      });
    },
    [queryClient, codTela],
  );

  useEffect(() => {
    for (let i = 0; i < loadingPageSearch.length; i++) {
      if (loadingPageSearch[i].key === codTela) {
        setLoading(false);
        setLoading(loadingPageSearch[i].value);
      }
    }
  }, [loadingPageSearch]);

  useEffect(() => {
    for (let i = 0; i < loadingTableSearch.length; i++) {
      if (loadingTableSearch[i].key === codTela) {
        setLoadingTable(false);
        setLoadingTable(loadingTableSearch[i].value);
      }
    }
  }, [loadingTableSearch]);

  useEffect(() => {
    const cacheLastSearch: CacheLastSearchProps | undefined =
      queryClient.getQueryData(`cacheLastSearch_${codTela}`);

    const infoTela = screensInformacoes.filter(
      (item: any) => item.cod_tela === codTela,
    );

    if (cacheLastSearch) {
      if (cacheLastSearch.shouldReturnSearch) {
        handleLoadingPageSearch(true, codTela);
        setTimeout(() => {
          if (infoTela.length > 0) {
            if (infoTela[0].flg_abre_pesquisa) {
              setAbrePesquisa(true);
              setShowTable(true);
            }
          }
          setPage(cacheLastSearch.pag);
          setLimit(cacheLastSearch.limite);
          RefetchView();
          handleLoadingPageSearch(false, codTela);
        }, 500);
      }
    }
  }, []);

  /**
   * @description Busca campos de filtros da respectiva tela antes da pesquisa ser feita
   * @var codTela - Codigo da tela a ser pesquisada
   */
  useEffect(() => {
    (async () => {
      const isAfterOnRowClick: CacheLastSearchProps | undefined =
        queryClient.getQueryData(`cacheLastSearch_${codTela}`);

      const { data } = await api.get(`/tela-campos/${codTela}`);

      if (!isAfterOnRowClick || listaDeFiltrosOrdenados.length <= 0)
        orderCampos(data);

      if (!isAfterOnRowClick) preferenciaTelaRequest();

      GetFilterScreen();
    })();
  }, [codTela]);

  useEffect(() => {
    if (!showTable) {
      const isAfterOnRowClick: CacheLastSearchProps | undefined =
        queryClient.getQueryData(`cacheLastSearch_${codTela}`);

      if (!isAfterOnRowClick) {
        handlefiltersBlock();
      }
    }
  }, [showTable]);

  async function GetFilterScreen() {
    const infoCardAtivo: any = queryClient.getQueryData(
      `infoCardAtivo_${codTela}`,
    );
    const isAfterOnRowClick: CacheLastSearchProps | undefined =
      queryClient.getQueryData(`cacheLastSearch_${codTela}`);

    if (infoCardAtivo) {
      setIdFiltro('');
      setUpdateCard(true);
      setDataEdit({
        cod_card: infoCardAtivo.cod_card,
        des_card: infoCardAtivo.des_card || '',
        cor: infoCardAtivo.cor || '',
      });
      setIsCardFixed(infoCardAtivo.cod_card === infoCardAtivo.cod_card_fixado);
      setShowTable(true);
      setValue('shouldClearFilter', true);

      if (!isAfterOnRowClick) {
        debouncedFn(() => {
          refetch();
        }, 1000);
      }
    } else {
      const infoTela = screensInformacoes.filter(
        (item: any) => item.cod_tela === codTela,
      );
      if (infoTela.length > 0) {
        if (infoTela[0].flg_abre_pesquisa && !isAfterOnRowClick) {
          setAbrePesquisa(true);
          setShowTable(true);
          refetch();
        }
      }
    }
  }

  const getOrderBy = (columnsSeach: any) => {
    if (columnsSeach) {
      const orderBy = columnsSeach
        .filter((column: any) => column.order !== -1)
        .map(
          ({ field, order, sortingOrder }: any) =>
            `${field} ${sortingOrder[order]}`,
        );

      return orderBy;
    }

    return null;
  };

  const orderCampos = async (data: any) => {
    if (data.success && data.data) {
      data.data.sort(function (a: any, b: any) {
        if (a.des_campo < b.des_campo) {
          return -1;
        }
        if (a.des_campo > b.des_campo) {
          return 1;
        }
        return 0;
      });

      const camposDeFiltro = data.data.filter(
        (campo: any) => campo.flg_filtro === true,
      );
      const chunkSize = Math.ceil(camposDeFiltro.length / 4);
      const result: any = [];
      for (let i = 0; i < chunkSize; i++) {
        result[i] = [];
      }
      camposDeFiltro.forEach((e: any, i: any) => {
        result[i % chunkSize].push(e);
      });
      setListaDeFiltrosOrdenados(result);
      setSearchFields(result);
    }
  };

  const preferenciaTelaRequest = async () => {
    await api.post(`/tela-usuario/${codTela}`);
  };

  useEffect(() => {
    (async () => {
      const hasLinkMaterial = screensInformacoes.filter(
        (item: any) => item.cod_tela === codTela,
      );

      if (hasLinkMaterial.length > 0) {
        if (hasLinkMaterial[0].linkMaterial) {
          setLinkMaterial(hasLinkMaterial[0].linkMaterial);
        }
      } else {
        const { data } = await api.get(`telasLinkMaterial/${codTela}`);

        if (data.success && data.data) {
          setLinkMaterial(data.data.link_material);
        }
      }
    })();
  }, [codTela]);

  const resetBusca = useCallback(() => {
    setInputSearch('');
    setListaDeFiltrosOrdenados(searchFields);
  }, [searchFields]);
  async function fixedCard() {
    if (dataEdit?.cod_card) {
      await api.put(`/tela-usuario/${codTela}`, {
        cod_card: isCardFixed ? null : dataEdit.cod_card,
      });
      setIsCardFixed(!isCardFixed);
    }
  }
  async function FocusFilter(index: string) {
    setTimeout(async () => {
      const input = await document.getElementById(index);
      input?.focus();
    }, 500);
  }
  const validationsFiltersBlock = useCallback((itens: Filter[]) => {
    let result = true;
    // eslint-disable-next-line no-restricted-syntax
    for (const item of itens) {
      if (
        item.isBlock &&
        !item.des_valor &&
        item.condicao < 7 &&
        item.des_valor !== 0
      ) {
        setIdFiltro(item.index);
        FocusFilter(item.index);
        result = false;
        break;
      }
    }
    return result;
  }, []);

  const handleSortField = (field: string) => {
    queryClient.removeQueries(`cache_tela_${codTela}`);
    const updatedColumns: ColumnProps[] = [...columns];
    const index = updatedColumns.findIndex((item) => item.field === field);

    if (index !== -1) {
      const currentOrder = updatedColumns[index]?.order ?? 0;

      const newOrder = currentOrder === 0 ? 1 : 0;

      updatedColumns[index].order = newOrder;

      setSortCampo({
        order: updatedColumns[index].order,
        codCampo: updatedColumns[index].codCampo,
      });
    }

    const sort: SortCamposProps[] = updatedColumns.map((item) => {
      let sortOrder: 'asc' | 'desc' | null;

      if (item.order === -1) {
        sortOrder = null;
      } else if (item.order === 0) {
        sortOrder = 'asc';
      } else {
        sortOrder = 'desc';
      }

      return {
        field: item.field,
        width: 70,
        minWidth: 70,
        sort: sortOrder,
      };
    });

    setColumns(updatedColumns as never[]);
    setSortCampos(sort);
    RefetchView();
  };

  const chamaSwetAlert = useCallback(async () => {
    const confirm = await MySwal.fire({
      title: ``,
      text: 'Deseja pesquisar sem nenhum critério?',
      showCancelButton: true,
      confirmButtonColor: '#0065FF',
      cancelButtonColor: '#DE350B',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then(async (result: any) => {
      return result.isConfirmed;
    });
    return confirm;
  }, []);

  const removeFiltrosOnSearch = useCallback(
    async (filtersData: any) => {
      if (!isRemoveClick) {
        // eslint-disable-next-line no-restricted-syntax
        for await (const filter of filtersData) {
          if (
            filter.des_valor !== 0 &&
            !filter.des_valor &&
            filter.condicao !== 7 &&
            filter.condicao !== 8
          ) {
            if (!filter.isBlock) {
              await RemoveFilter(codTela, filter.index);
              filtersData = filtersData.filter(
                (item: any) => item.index !== filter.index,
              );
            }
          }
        }
      }
    },
    [RemoveFilter, codTela, isRemoveClick],
  );

  const getRowSearch = useCallback(
    async (dataFields: any) => {
      const datafieldNames = await api.get(`/tela-campos/${codTela}`);

      orderCampos(datafieldNames.data);

      const fieldNames: Field[] = datafieldNames.data.data;
      if (dataFields.data) {
        setRowsCount(dataFields.count);
        await dataFields.fields.map((item: any) => {
          const itemDesc = fieldNames.find((field) => {
            const idName = field.nome_bd.split('.')[1];
            return idName.indexOf(item.name) >= 0;
          });
          const itemVisi = fieldNames.find((tes) => {
            const idName = tes.nome_bd.split('.')[1];
            if (idName === item.name) {
              return tes.flg_visualizacao;
            }
            return false;
          });

          return {
            codCampo: itemDesc?.cod_campo,
            field: item.name,
            headerName: itemDesc?.des_campo,
            width: 300,
            visibility: !!itemVisi,
          };
        });
        const dataRows = await dataFields.data.map(
          (item: any, idx: number) => ({
            ...item,
            id: idx,
          }),
        );

        setShowTable(true);
        timeLoading();
        return {
          data: dataRows,
        };
      }

      return { data: [] };
    },
    [codTela],
  );

  const verifyHasFilterEmpty = useCallback(() => {
    const filtros = filtersContexto.find(
      (filterContexto) => filterContexto.codTela === codTela,
    )?.itens;
    //  quando for aberto um filtro com um outro filtro ja aberto e vazio o filtro anterior sera fechado
    filtros?.forEach((filtro) => {
      if (
        (filtro.des_valor === '' ||
          filtro.des_valor === null ||
          filtro.des_valor === undefined) &&
        filtro.condicao !== 7 &&
        filtro.condicao !== 8
      ) {
        RemoveFilter(codTela, filtro.index);
      }
    });
  }, [RemoveFilter, codTela, filtersContexto]);

  const removeFilterWithoutValue = useCallback((filtersData: Filter[]) => {
    /**
     * Filtragem dos filtros que não são das condições preenchidos ou não
     * preenchidos e que estão com o valor undefined, caso satisfaça ele
     * cria um novo array sem o Filtro, para não causar envio de dados
     * undefined em condições que esperam algum valor informado.
     */
    const filtersDataWithValue: any = filtersData.filter(
      (item) =>
        item.des_valor !== undefined || // verifica se des_valor é undefined OU
        item.condicao === 8 || // verifica se condicao é igual a 8 OU
        item.condicao === 7, // verifica se condicao é igual a 7
    );

    return filtersDataWithValue;
  }, []);

  const timeLoading = useCallback(() => {
    setTimeout(() => {
      handleLoadingTableSearch(false, codTela);
    }, 500);
  }, [codTela, handleLoadingTableSearch]);

  const handleSearch = useCallback(async (): Promise<DataResponse> => {
    handleLoadingTableSearch(true, codTela);
    setOpenPopupView(false);
    resetBusca();
    setBackSearch(false);
    setIdFiltro('');
    if (!validationsFiltersBlock(filtersDaTela)) {
      return {
        data: [],
      };
    }

    const filterScreen = filtersContexto.find(
      (item: IFilter) => item.codTela === codTela,
    );

    if (!showTable && filterScreen?.codTela === undefined) {
      const confirmSearchWithoutFilter = await chamaSwetAlert();
      if (!confirmSearchWithoutFilter) {
        timeLoading();

        return {
          data: [],
        };
      }
      setUpdateCard(false);
      AddFilterOrUpdate({
        codTela,
        edit: false,
        itens: [],
      });
    }

    if (oldPage === 1 && page === 1) {
      handleLoadingTableSearch(true, codTela);
    }
    setLoadingTable(true);

    const filtersData = filterScreen?.itens || [];
    removeFiltrosOnSearch(filtersData);
    const filtersDataWithValue: any = removeFilterWithoutValue(filtersData);

    if (filtersDataWithValue.length > 0) setValue('shouldClearFilter', true);

    const orderByCache: any = queryClient.getQueryData(
      `cacheLastSearch_${codTela}`,
    );

    const orderBy =
      orderByCache?.orderBy.length > 0
        ? orderByCache.orderBy
        : columns
            .filter((item: any) => item.order !== -1)
            .map((item: any) => {
              return `${item.field} ${item.order === 0 ? 'asc' : 'desc'}`;
            });

    const { data: dataFields } = await api.post('/search', {
      tela_id: codTela,
      filters: filtersDataWithValue,
      user_id: await user?.id,
      orderBy,
      page,
      limit,
    });

    const { totals, pagination } = dataFields;

    if (page > pagination.lastPage + 1) {
      setPage(pagination.lastPage + 1);
    }
    setTotalsAgregadores(totals);

    if (!backSearch) {
      const searchRows = await getRowSearch(dataFields);

      setShowTable(true);

      timeLoading();
      setLoadingTable(false);
      handleLoadingTableSearch(false, codTela);
      return { data: searchRows.data };
    }

    timeLoading();
    setLoadingTable(false);
    handleLoadingTableSearch(false, codTela);
    await refetch();
    return { data: [] };
  }, [
    codTela,
    filtersDaTela,
    user,
    showTable,
    columns,
    limit,
    oldPage,
    page,
    AddFilterOrUpdate,
    chamaSwetAlert,
    filtersContexto,
    getRowSearch,
    removeFilterWithoutValue,
    removeFiltrosOnSearch,
    resetBusca,
    validationsFiltersBlock,
    queryClient,
    handleLoadingTableSearch,
    timeLoading,
    setValue,
    backSearch,
  ]);

  const {
    data: responseData,
    remove,
    refetch,
  } = useCacheTelas({
    page,
    codTela,
    handleSearch,
    enabledCaching,
  });

  const handleSetLimit = (value: number) => {
    if (View !== 'PADRÃO') {
      setLimit(value);
    }
  };

  const changeLimit = useCallback(
    async (value: number) => {
      handleLoadingPageSearch(true, codTela);
      setLoadingTable(true);

      setLimit(value);

      setTimeout(async () => {
        queryClient.removeQueries(`cache_tela_${codTela}`);
        await refetch();
        handleLoadingPageSearch(false, codTela);
      }, 500);
    },
    [codTela, queryClient, refetch, handleLoadingPageSearch],
  );

  const RefetchView = useCallback(async () => {
    setLoadingTable(true);
    queryClient.removeQueries(`cache_tela_${codTela}`);
    await refetch();
    setLoadingTable(false);
  }, [refetch, codTela, queryClient]);

  const handleResetCache = useCallback(() => {
    remove();
    setPage(1);
    setOldPage(1);
    queryClient.removeQueries(`cache_tela_${codTela}`);
  }, [codTela, queryClient, remove]);

  async function returnSearch() {
    setPage(1);
    setOldPage(1);
    setLimit(10);
    setBackSearch(true);
    setValue('search', '');

    onResetAllStates();

    /**
     * Quando havia um card modificado as execuções abaixo
     * faziam com que fosse perdido a busca antes mesmo do
     * usuario realizar a confirmação
     */
    // setColumns([]);
    // setEnabledCaching(false);
    // setValue('shouldClearFilter', false);
    // queryClient.removeQueries(`cache_tela_${codTela}`);
  }

  const handleLoadNewPage = useCallback(
    async (newPage: number) => {
      if (newPage <= 0) {
        setPage(1);
      } else {
        setPage(newPage);
      }
      setEnabledCaching(true);
      setOldPage(page);
    },
    [page],
  );

  const verifyHasFilterOnContext = useCallback(
    (item: Field) => {
      let hasFilterOnContext = false;

      const filtersContextTela = filtersContexto.find(
        (filterContexto) => filterContexto.codTela === codTela,
      )?.itens;

      filtersContextTela?.forEach((filterContextTela) => {
        if (filterContextTela.cod_campo === item.cod_campo) {
          hasFilterOnContext = true;
          setIdFiltro(filterContextTela.index);
        }
      });

      return hasFilterOnContext;
    },
    [filtersContexto, codTela],
  );

  const handleOpenFiltro = useCallback(
    (item: Field, isBlock = false) => {
      let entrouSwitch = true;
      const hasFilterOnContext = verifyHasFilterOnContext(item);
      verifyHasFilterEmpty();

      if (!hasFilterOnContext) {
        const IdGenerator = nanoid();
        setIdFiltro(IdGenerator);

        const addFilter: IFilter = {
          codTela,
          edit: isUpdateCard,
          flg_pesquisado: false,
          name_card: dataEdit?.des_card || '',
          cor: dataEdit?.cor || '',
          itens: [
            {
              index: IdGenerator,
              cod_tipo_dados: item.cod_tipo_dados,
              des_nome: item.des_campo,
              des_valor: undefined,
              condicao: 0,
              isBlock,
              nome_bd: item.nome_bd,
              cod_tipo_info: item.cod_tipo_info,
              cod_campo: item.cod_campo,
              expressao_validacao: item.expressao_validacao,
              listagem_info: item.listagem_info,
              tipo_expressao: item.tipo_expressao,
            },
          ],
        };
        switch (item.cod_tipo_dados) {
          case 1:
            addFilter.itens[0].des_valor = format(new Date(), 'yyyy-MM-dd');
            break;
          case 2:
            addFilter.itens[0].des_valor = format(
              new Date(),
              'yyyy-MM-dd HH:mm',
            );
            break;
          case 5:
            addFilter.itens[0].condicao = item.cod_tipo_info > 0 ? 0 : 4;
            break;
          case 6:
            addFilter.itens[0].index = item.flg_filtro_obrigatorio
              ? String(item.cod_campo)
              : IdGenerator;
            addFilter.itens[0].des_valor = 'false';
            break;
          default:
            entrouSwitch = false;
            break;
        }

        if (
          entrouSwitch ||
          item.cod_tipo_dados === 3 ||
          item.cod_tipo_dados === 4
        )
          AddFilterOrUpdate(addFilter);
      }
    },
    [
      AddFilterOrUpdate,
      codTela,
      dataEdit?.cor,
      dataEdit?.des_card,
      isUpdateCard,
      verifyHasFilterOnContext,
      verifyHasFilterEmpty,
    ],
  );

  const handleAddFilter = useCallback(
    async (item: Filter) => {
      const cacheLastSearch: CacheLastSearchProps | undefined =
        queryClient.getQueryData(`cacheLastSearch_${codTela}`);
      setInputSearch('');
      // evita duplicidade da requisicao quando for retorno de registro
      if (cacheLastSearch?.shouldReturnSearch) {
        return;
      }

      const oldItems = await filtersDaTela.filter(
        (i) => i.index !== item.index,
      );
      const Allfitros = await [...oldItems, item];
      await AddFilterOrUpdate({
        codTela,
        edit: isUpdateCard,
        flg_pesquisado: false,
        name_card: (await dataEdit?.des_card) || '',
        cor: (await dataEdit?.cor) || '',
        itens: [item],
      });

      setValue('shouldClearFilter', true);

      if (validationsFiltersBlock(Allfitros)) {
        setPage(1);
        setEnabledCaching(false);
        queryClient.removeQueries(`cache_tela_${codTela}`);
        refetch();
      }
    },
    [
      filtersDaTela,
      AddFilterOrUpdate,
      codTela,
      dataEdit?.cor,
      dataEdit?.des_card,
      isUpdateCard,
      validationsFiltersBlock,
      refetch,
      queryClient,
      setValue,
    ],
  );

  const removeFilter = useCallback(
    async (item: Filter) => {
      const { index } = item;
      RemoveFilter(codTela, index);

      const filtersContextTela: any =
        filtersContexto.find(
          (filterContexto) => filterContexto.codTela === codTela,
        )?.itens || [];

      if (filtersContextTela?.length === 0) {
        setValue('shouldClearFilter', false);
      }

      setIsRemoveClick(true);
      // if (
      //   item.des_valor ||
      //   item.condicao === 7 ||
      //   item.condicao === 8 ||
      //   item.condicao === 9 ||
      //   item.condicao === 10
      // ) {
      //   await RefetchView();
      // }
      await RefetchView();
    },
    [RemoveFilter, codTela, RefetchView, setValue, filtersContexto],
  );

  const removeAllFilters = useCallback(() => {
    RemoveFilterisBlock(codTela);
    setValue('shouldClearFilter', false);

    refetch();
  }, [RemoveFilterisBlock, codTela, refetch, setValue]);

  const getSearchFilter = (filtroDeCampos: string): void => {
    setInputSearch(filtroDeCampos);
    if (filtroDeCampos === '') {
      setListaDeFiltrosOrdenados(searchFields);
      return;
    }
    const filteredFields = searchFields.map((listaOrdenadaAlfabetica: any) => {
      return listaOrdenadaAlfabetica.filter((dadosDoCampo: any) => {
        if (dadosDoCampo.des_sinonimo) {
          return (
            dadosDoCampo.des_campo
              .toUpperCase()
              .indexOf(filtroDeCampos.toUpperCase()) >= 0 ||
            dadosDoCampo.des_sinonimo
              .toUpperCase()
              .indexOf(filtroDeCampos.toUpperCase()) >= 0
          );
        }
        return (
          dadosDoCampo.des_campo
            .toUpperCase()
            .indexOf(filtroDeCampos.toUpperCase()) >= 0
        );
      });
    });
    const listaDeArrayPreenchidos = filteredFields.filter((n: any) => n.length);
    setListaDeFiltrosOrdenados(listaDeArrayPreenchidos);
  };

  async function handlefiltersBlock() {
    const infoCardAtivo: any = await queryClient.getQueryData(
      `infoCardAtivo_${codTela}`,
    );

    if (!infoCardAtivo && !showTable) {
      const { data } = await api.get(
        `/tela-campos-filtros-obrigatorios/${codTela}`,
      );
      if (data.success && data.data) {
        const orderFilters = data.data.sort(function (a: any, b: any) {
          if (a.num_ordem < b.num_ordem) return -1;

          return true;
        });

        // eslint-disable-next-line no-restricted-syntax
        for await (const campofilter of orderFilters) {
          const filtesExist = filtersDaTela.find(
            (item) => item.cod_campo === campofilter.cod_campo,
          );
          if (!filtesExist) {
            handleOpenFiltro(campofilter, true);
            setIdFiltro(campofilter.index);
            await FocusFilter(campofilter.index);
          }
        }
      }
    }
  }

  function returnCount(): string {
    let newLimit = limit;
    if (limit > rowsCount) newLimit = rowsCount;
    if (page === 1) {
      return `1 - ${newLimit} de ${rowsCount}`;
    }
    const count = page * newLimit;
    if (count > rowsCount) {
      return `${count - limit + 1} - ${rowsCount} de ${rowsCount}`;
    }
    return `${count - limit + 1} - ${count} de ${rowsCount}`;
  }

  const handleColumns = useCallback((val) => {
    setColumns(val);
  }, []);

  return (
    <SeachContext.Provider
      value={{
        codTela,
        setShowImpressao,
        showImpressao,
        isUpdateCard,
        linkMaterial,
        limit,
        View,
        setIsUpdateLimit,
        fixedCard,
        colors,
        isCardFixed,
        loading,
        showTable,
        rowsCount,
        setOpenFields,
        setLimit,
        getSearchFilter,
        page,
        isOpenFields,
        returnCount,
        dataEdit,
        inputSearch,
        handleLoadNewPage,
        RefetchView,
        setSearchOnClick,
        handleOpenFiltro,
        resetBusca,
        removeAllFilters,
        setView,
        listaDeFiltrosOrdenados,
        handleColumns,
        setEnabledCaching,
        setIsOpenModal,
        handleSearch,
        searchOnClick,
        isUpdateLimit,
        removeFilter,
        setSortCampos,
        setIdFiltro,
        newData,
        orderTelas,
        validationsFiltersBlock,
        setOpenOrderModal,
        onlyReport,
        orderModulo,
        setIsRemoveClick,
        handleAddFilter,
        sortCampo,
        setOpenOrderTelasModal,
        setShowTable,
        returnSearch,
        handleResetCache,
        enabledCaching,
        idFiltro,
        setIsCardFixed,
        setLoading,
        setDataEdit,
        handleSortField,
        backSearch,
        loadingTable,
        handlefiltersBlock,
        setBackSearch,
        responseData,
        columns,
        setUpdateCard,
        isOpenModal,
        user,
        openOrderModal,
        totalsAgregadores,
        onRowClick,
        openOrderTelasModal,
        sortCampos,
        filtersDaTela,
        updateFiltroContextoWhenOnRowClick,
        queryClient,
        refetch,
        handleSetLimit,
        changeLimit,
        abrePesquisa,
        getOrderBy,
        tipoAcesso,
        setTipoAcesso,
        setValue,
        searchTextWatch,
        getValues,
        openPopupView,
        setOpenPopupView,
        customButtons,
      }}
    >
      {children}
    </SeachContext.Provider>
  );
}

export const useSearch = (): SearchContextData => {
  return useContext(SeachContext);
};
