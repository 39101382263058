import React from 'react';
import { SeparatorLine } from './styles';

interface FormProps {
  labelText?: string;
  color?: string;
  lineColor?: string;
  fontSize?: string;
  textPos?: 'start' | 'center' | 'end';
  childrenWidth?: string;
  borderStyle?:
    | 'dotted'
    | 'dashed'
    | 'solid'
    | 'double'
    | 'groove'
    | 'ridge'
    | 'inset'
    | 'outset'
    | 'initial'
    | 'inherit';
}
/**
 * @param labelText Texto que aparecerá em frente a linha de separação
 * @returns Componente para separar áreas do formulário como dados pessoais, contato, endereço, etc.
 */
const SeparatorVertical: React.FC<FormProps> = (props) => {
  const {
    labelText,
    color = '#bdbdbd',
    lineColor = '#ededed',
    fontSize = '14px',
    textPos = 'start',
    childrenWidth = '98.8%',
    children,
    borderStyle = '',
  } = props;
  return (
    <SeparatorLine
      color={color}
      lineColor={lineColor}
      fontSize={fontSize}
      textPos={textPos}
      childrenWidth={childrenWidth}
      borderStyle={borderStyle}
      style={{ marginTop: '20px' }}
    >
      <div className="or-separator">
        <div className="vertical-line" />
        <div>{labelText}</div>
        <div className="vertical-line" />
      </div>
    </SeparatorLine>
  );
};

export default SeparatorVertical;
