import React from 'react';
import ComponenteDeImpressao from '~/components/Impressao/ImpressaoContent';
import { useSearch } from '../../SearchContext';

export const Impressao: React.FC = () => {
  const { filtersDaTela, codTela, showImpressao, setShowImpressao } =
    useSearch();

  if (!showImpressao) {
    return null;
  }

  return (
    <ComponenteDeImpressao
      show={showImpressao}
      codTela={codTela}
      handleShow={setShowImpressao}
      filters={filtersDaTela}
    />
  );
};
