import styled from 'styled-components';

export const ContainerTipoAcesso = styled.div`
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 1199px) {
    width: 100% !important;
    margin-top: 20px;
    justify-content: center;
  }

  .containerSearchInput {
    position: relative;
    input {
      border: 2px solid var(--ds-border-neutral, #dfe1e6);
    }
    svg {
      position: absolute;
      font-size: 25px;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

export const BtnGroupContainer = styled.div`
  display: flex;
  align-items: center;
  z-index: 0;
  margin-right: 20px;
  border: 2px solid var(--ds-border-neutral, #dfe1e6);
  border-radius: 0px 4px 4px 0px;
  background-color: yellow;

  .btn-group {
    display: flex;
    justify-content: center;
    border-radius: 3px;
    background-color: #ecedf0;
    color: rgb(37, 56, 88);
    border: none;
    font-size: 0.9rem;
    min-width: 100px;
    padding-top: 9px;
    height: 40px;

    &:active {
      outline: none;
      box-shadow: none !important;
    }
    &:focus {
      outline: none;
      box-shadow: none !important;
    }
  }

  .btn-group-active {
    background-color: #57069e;
    color: #ecedf0;
  }
  @media screen and (max-width: 991px) {
    margin-right: 10px;
    .loja {
      min-width: 50px !important;
    }
    .cards {
      min-width: 94px !important;
    }
    .publico {
      min-width: 70px !important;
    }
  }

  @media screen and (max-width: 766px) {
    margin-right: 5px;
  }
`;
