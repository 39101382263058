import styled from 'styled-components';

interface ISeparator {
  color: string;
  background: string;
  lineColor: string;
  fontSize: string;
  textPos: string;
  childrenWidth: string;
  borderStyle: string;
  sidePadding: string;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const SeparatorLine = styled.div<ISeparator>`
  margin-top: 10px;
  padding: 0;
  .elements {
    padding: ${(props) => props.sidePadding};
    display: flex;
    justify-content: space-between;
    position: relative;
    .children {
      width: ${(props) => props.childrenWidth};
    }
  }

  p {
    color: ${(props) => props.color};
    flex: 1;

    text-align: ${(props) => props.textPos};
    border-bottom: 1px solid ${(props) => props.lineColor};
    border-style: ${(props) => props.borderStyle};
    line-height: 0.1em;
    margin: 10px 0 20px;
  }
  p span {
    font-size: ${(props) => props.fontSize};
    background: ${(props) => props.background} !important;
    /* font-weight: 500; */
    padding: ${(props) =>
      props.textPos === 'center' ? '0 5px 0px 5px' : '0 5px 0px 0px'};
  }
`;
