import React from 'react';
import { useSearch } from '../../SearchContext';
import InputSelect from '~/components/Inputs/InputSelect';
import Pagination from '~/components/Pagination';
import { ContainerLimit } from '../../styles';

const Paginated: React.FC = () => {
  const {
    linkMaterial,
    limit,
    rowsCount,
    setIsUpdateLimit,
    changeLimit,
    returnCount,
    handleLoadNewPage,
    page,
    codTela,
    RefetchView,
  } = useSearch();
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div style={{ minWidth: '100px', paddingLeft: '10px' }}>
        {linkMaterial && (
          <div className="containerMaterial">
            <a
              href={linkMaterial}
              target="_blank"
              className="linkMaterial"
              rel="noreferrer"
            >
              Precisa de ajuda?
            </a>
          </div>
        )}
      </div>
      <ContainerLimit>
        <label htmlFor="limit-pagination">Registros por página</label>
        <InputSelect
          label=""
          id="limit-pagination"
          className="limit-pagination"
          options={[
            { value: 10, label: '10' },
            { value: 20, label: '20' },
            { value: 30, label: '30' },
          ]}
          value={{ value: limit, label: `${limit}` }}
          onChange={async (option: { value: number; label: string }) => {
            const { value } = option;
            setIsUpdateLimit(true);
            changeLimit(value);
          }}
        />
      </ContainerLimit>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          gap: '12px',
          marginLeft: '0.625rem',
        }}
      >
        {rowsCount > 0 && rowsCount === 1 && (
          <p className="my-2">{rowsCount} registro encontrado.</p>
        )}
        {rowsCount > 1 && <p className="my-2">{returnCount()}</p>}
        {rowsCount <= 0 && <p className="my-2">0 registros encontrados.</p>}

        <Pagination
          onPageChange={handleLoadNewPage}
          currentPage={page}
          tela={codTela}
          rowsCount={rowsCount}
          limit={limit}
          updatePage={RefetchView}
        />
      </div>
    </div>
  );
};

export default Paginated;
