import React, { useContext } from 'react';
import { useSearch } from '../../SearchContext';
import Card from '~/components/Card';
import useDebounce from '~/hooks/useDebounce';
import { loadingContext } from '~/context/loading';

export const CardContainer: React.FC = () => {
  const {
    codTela,
    isUpdateCard,
    isCardFixed,
    showTable,
    setIsOpenModal,
    setShowTable,
    setIsCardFixed,
    setDataEdit,
    backSearch,
    setBackSearch,
    setUpdateCard,
    isOpenModal,
    refetch,
    searchTextWatch,
    tipoAcesso,
  } = useSearch();

  const { debouncedFn } = useDebounce();
  const { handleLoadingPageSearch } = useContext(loadingContext);

  return (
    <Card
      codTelaProp={codTela}
      handleSearch={() => {
        setShowTable(true);
        debouncedFn(() => {
          refetch();
        }, 500);
      }}
      showTable={showTable}
      onShowTable={setShowTable}
      isOpenModal={isOpenModal}
      setIsOpenModal={setIsOpenModal}
      isUpdateCard={isUpdateCard}
      setUpdateCard={setUpdateCard}
      BackSearchProps={backSearch}
      setBackSearchProps={setBackSearch}
      setDataEdit={setDataEdit}
      setIsCardFixed={setIsCardFixed}
      isCardFixed={isCardFixed}
      setLoading={(val: boolean) => handleLoadingPageSearch(val, codTela)}
      textFiltro={searchTextWatch}
      tipoFiltro={tipoAcesso}
    />
  );
};
