import styled from 'styled-components';

interface ISeparator {
  color: string;
  lineColor: string;
  fontSize: string;
  textPos: string;
  childrenWidth: string;
  borderStyle: string;
}

export const SeparatorLine = styled.div<ISeparator>`
  /* width: 200px; */
  height: 90%;
  display: flex;
  justify-content: center;

  .or-separator {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${(props) => props.color};
    /* color: #d3d7da; */
  }

  .vertical-line {
    border-left: 1px solid ${(props) => props.color};
    border-style: ${(props) => props.borderStyle};
    flex-grow: 1;
    width: 1px;
  }
`;
